<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- <this-header /> -->
    <validation-observer ref="simpleRules">
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>

          <div class="col-10">
            <b-button
              class="btn btn-gradient-primary mb-1 mr-50"
              @click="$router.push({ name: 'notice'})"
            >
              <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
            </b-button>

            <div class="card">
              <div
                class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top"
              />

              <div class="d-flex justify-content-between align-items-center px-2">
                <div>
                  <h3 class="font-weight-bolder">
                    {{ items.postName }}
                  </h3>
                </div>
              </div>
              <div class="p-2">
                <span
                  class="noti-detail"
                  v-html="items.content"
                />
              </div>
            </div>
          </div>

          <!-- <div class="col-md-4 col-lg-4 col-sm-12">
            <div class="card">
              <div
                class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top"
              />

              <div class="px-2">
                <div>
                  <h3 class="font-weight-bolder">
                    {{ $t('key-174') }}
                  </h3>
                </div>
              </div>

              <b-row
                class="match-height"
              >
                <div
                  v-for="(item,index) in itemsall"
                  :key="index"
                  class="col-12 mb-1"
                >
                  <div
                    class="p-2 card-notice"
                    @click="Checkeditem(item)"
                  >
                    <img
                      v-if="item.file_file"
                      :src="GetImg('Notice_file',item.file_file)"
                      alt=""
                      width="50px"
                      height="50px"
                    >
                    <span
                      class="ml-1 mb-0"
                    >{{ item.postName }} {{ index+1 }}</span>
                  </div>
                </div>

                <div class="col-12 mb-1">
                  <div class="p-2">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt=""
                      width="50px"
                      height="50px"
                    >
                    <span class="ml-1 mb-0">หัวข้อประกาศ2</span>
                  </div>
                </div>

              </b-row>
              <div class="demo-spacing-0 d-flex justify-content-end mb-2">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                  @input="getDataAll()"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>

            </div>
          </div> -->

        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BButton, BPagination,
} from 'bootstrap-vue'
import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
// import moment from 'moment-timezone'
import SideBar from './profile/component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    SideBar,
    // ThisHeader,
    BRow,
    ValidationObserver,
    BButton,
    BPagination,
  },
  data() {
    return {
      isActive: 'all',
      showOver: false,
      ID: this.$route.params.id,
      items: [],
      itemsall: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 1,
    }
  },
  mounted() {
    this.getData()
    // this.getDataAll()
  },
  methods: {
    async getData() {
      this.showOver = true
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Notice/getDataOne', { params })
        this.items = res
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    async getDataAll() {
      try {
        const params = {
          perPage: this.perPage,
          currentPage: this.currentPage,
        }
        const { data: res } = await this.$http.get('/Notice/getDataNoticeCus', { params })
        // res.data.map(ele => {
        //   ele.file_file = null
        // })
        this.itemsall = res.data
        this.totalRows = res.total
        // this.ShowIMG()

        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    ShowIMG() {
      const Id = this.itemsall.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/Notice/GetDataimg', { params })
        .then(response => {
          // console.log(response)
          response.data.forEach((data, index) => {
            this.itemsall[index].file_file = data.file_file
          })
        })
        .catch(err => console.log(err))

      // Notice/GetDataimg
    },
    Checkeditem(data) {
      this.ID = data._id
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.card-notice:hover{
     transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

</style>
